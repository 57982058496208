<script setup>
import { useTools } from '@/composables/tools'
import { useChat } from '@/composables/chat'
import { theme } from "#tailwind-config";
import { watchDebounced, useElementBounding, useMagicKeys, whenever } from '@vueuse/core'
import { read, writeFileXLSX } from "xlsx";
import '~/assets/css/tabulator_style.css';
import { Loader2 } from 'lucide-vue-next'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import { onClickOutside } from '@vueuse/core'
import { row } from '@unovis/ts/components/timeline/style';
import { useDockStore } from '@/stores/dockStore'
import TabulatorTable from './TabulatorTable.vue'
import { useProjectData } from '~/composables/projectData'
import PanelComponent from '@/components/PanelComponent.vue'

import { useDataUtils } from '@/composables/dataUtils';


const { mapSpeciesToColors } = useDataUtils();
// Store imports and setup
const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)
const dockStore = useDockStore();
const uiStore = useUiStore()

// Composables
const { tools } = useTools()
const { chatLLM } = useChat()
const colorMode = useColorMode();
const client = useSupabaseClient();

// Refs and computed properties
const plantscheduleRef = ref(null)
const { width: plantscheduleWidth, height: plantscheduleHeight, top: plantscheduleTop, left: plantscheduleLeft } = useElementBounding(plantscheduleRef)
const { windowWidth, chatDialogOpen } = storeToRefs(uiStore)
const beamSize = computed(() => Math.floor(windowWidth.value * 1.4))
const loggingEnabled = ref(false)

// Store refs
const { dragOver, panelProps, includeDataInChat, activePanel, saving, panelState, panelToRefresh, availablePanels } = storeToRefs(dockStore);
const { setDragOver, updatePanelState, mergeData, setActivePanel, addPanelState, removePanelToRefresh, setAvailablePanel, removeAvailablePanel, updatePanelRows, updatePanelColumns } = dockStore;

const isMobile = computed(() => {
  return windowWidth.value <= 760;
});

const enableHovers = ref({
  row: false,
  column: isMobile.value ? false : true,
})

// Props and emits
const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true },
  fetchedPanelData: { type: Object, required: false },
  testMode: { type: Boolean, required: false }
});

const emit = defineEmits(['error', 'update:shared-data'])

// State
const loading = ref(true);
const tabulator = ref(null)
const tables = ref(null)
const forceUseText = ref(null);
const title = ref('')
const inferredColumnTitles = ref(null)

const refreshedKey = ref(0)

const isEditingFalseOnMouseOut = ref(false)

// Computed properties
const tool_id = computed(() => tools.find(tool => tool.label === 'Schedule').uid)
const fileAnalysis = computed(() => {
  return props.panel?.data?.output?.[0]?.items || props.panel?.data?.output?.message?.[0]?.pages?.[0]?.items
})
const projectAnalysis = computed(() => {
  return props.panel?.data?.output?.tables
})

const metadata = ref(null)

// Methods
const log = (...args) => {
  if (loggingEnabled.value) {
    console.log(`[PlantSchedule.vue]`, ...args)
  }
}

const parseFileAnalysis = async (output) => {
  let useText = forceUseText.value;

  if (useText === null) {
    // Auto-detect if not forced
    const totalMdLength = output.reduce((sum, item) => sum + (item.md?.replace(/\s/g, '') || '').length, 0);
    const totalTextLength = output.reduce((sum, item) => sum + (item.text?.replace(/\s/g, '') || '').length, 0);
    useText = totalTextLength > totalMdLength * 1.2;
  }

  const flattenedArray = output.flatMap(item => useText ? item.text : item.md).filter(Boolean);

  let md_items = flattenedArray.join('\n\n');
  let { data: cached_grq_js } = useNuxtData(`grq-js-${props.panel_id}`)

  if (cached_grq_js.value?.data?.tables) {
    console.log('using cached grq response')
    return toRaw(cached_grq_js.value.data.tables);
  }
  // let res = ref({data: {tables: []}})
  console.log('getting grq response')
  loading.value = true
  let { data: res } = await useAsyncData(
    `grq-js-${props.panel_id}`, // Unique key for this request
    () => $fetch('/api/ai/grq-js', {
      method: 'POST',
      body: {
        prompt: md_items
      }
    })
  );
  loading.value = false
  return formatForTabulator(toRaw(res?.value?.data?.tables));
}

const getTables = async () => {
  if (projectAnalysis.value) {
    console.log('using cached project analysis', projectAnalysis.value)
    return formatForTabulator(toRaw(projectAnalysis.value));
  }
  const output = fileAnalysis.value
  console.log('output', output)
  const parsedTables = await parseFileAnalysis(output)
  return formatForTabulator(parsedTables);
}

const formatForTabulator = (parsedTables) => {
  if (!parsedTables) {
    console.log('could not get tabulator data');
    return null;
  }

  console.log('formatting for tabulator');

  // Check if parsedTables is already in {rows: [], columns: []} format
  if (Array.isArray(parsedTables.rows) && Array.isArray(parsedTables.columns)) {
    // Data is already in the correct format, just need to format columns
    const columns = parsedTables.columns.map(col => ({
      title: col.title || col.field,
      field: col.field,
      headerWordWrap: true,
      headerSort: true,
      editor: "input",
      resizable: "header",
      headerVertical: false,
      formatter: function (cell, formatterParams) {
        const value = cell.getValue();
        if (typeof value === 'number') {
          return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
        }
        return value;
      },
    }));

    return { columns, rows: parsedTables.rows };
  }

  // If not in the fallback format, process as before
  const allColumns = new Set();
  const rows = [];

  parsedTables.forEach((table, tableIndex) => {
    table.columns.forEach(col => allColumns.add(col));
    table.rows.forEach((row, rowIndex) => {
      const rowData = {
        id: `${tableIndex}-${rowIndex}`,
        _group: table.group_id || 'Plants'
      };
      table.columns.forEach((col, colIndex) => {
        rowData[col] = row[colIndex];
      });
      rows.push(rowData);
    });
  });

  const columns = Array.from(allColumns).map(col => ({
    title: col,
    field: col,
    headerWordWrap: true,
    headerSort: true,
    editor: "input",
    resizable: "header",
    headerVertical: false,
    formatter: function (cell, formatterParams) {
      const value = cell.getValue();
      if (typeof value === 'number') {
        return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
      }
      return value;
    },
  }));

  return { columns, rows };
};

const downloadAs = (type) => {
  console.log('downloading', tabulator)
  try {
    tabulator.value.download({
      filename: `${selectedProject.value.title} (${panelState?.[panel_id]?.fileMetadata?.filename}).${type}`,
      sheetName: selectedProject.value.title,
      fileType: type
    });
  } catch (e) {
    console.error('error downloading', e)
  }
}

const handleClick = () => {
  const newActivePanel = props.panel.panel.name;
  if (newActivePanel !== activePanel.value) {
    setActivePanel(newActivePanel);
  }
}

const updateSharedData = () => {
  if (tables.value && metadata.value) {
    console.log('updating Shared Data', tables.value, metadata.value)
    const botanicalNameKey = metadata?.value?.columnToCanonical?.['botanicalname'];
    const botanicalNames = tables.value?.rows?.map(row => row[botanicalNameKey]).filter(Boolean);
    console.log('updateSharedData botanicalNames:', botanicalNames);
    const colorMap = mapSpeciesToColors(botanicalNames);
    const taxon = botanicalNames?.map(name => ({
      name,
      color: colorMap[name],
      visible: true
    }));
    console.log('updateSharedData taxon:', taxon);
    const sharedData = {
      botanicalNames,
      metadata: metadata.value,
      tool_uid: tool_id.value
    };
    
    emit('update:shared-data', sharedData);

    // // Update the panelState directly
    // addPanelState({
    //   [props.panel_id]: {
    //     ...panelState.value[props.panel_id],
    //     sharedData
    //   }
    // });

    console.log('Registering PlantSchedule as available panel:', props.panel_id, sharedData);
    setAvailablePanel({
      panel_id: props.panel_id,
      tool_uid: tool_id.value,
      metadata: sharedData
    });
  }
};

// Watch for changes in tables and metadata
watch([() => tables.value, () => metadata.value], ([newTables, newMetadata]) => {
  updateSharedData()
  if (newTables && newMetadata) {
    console.log('newTables and newMetadata', newTables, newMetadata)
    
  } else {
    console.log('Removing PlantSchedule from available panels:', props.panel_id);
    removeAvailablePanel(props.panel_id);
  }
}, { deep: true });

// Use projectData composable
const projectData = useProjectData();
const { updatePanelStateOutput, updatedAndSavedPanel, setupPanelWatchers, getImageForProject } = projectData;

// Setup watchers

watch(panelToRefresh, (newVal) => {
  if(newVal.includes('PlantSchedule')){
    refreshPanel()
    removePanelToRefresh('PlantSchedule')
  }
})

const refreshPanel = async () => {
  refreshedKey.value++;
  console.log('Refreshing panel, current tables:', tables.value);
  if (!tables.value) {
    console.log('Fetching tables...');
    tables.value = await getTables();
    console.log('Fetched tables:', tables.value);
  }
  if (tables.value && !metadata.value) {
    console.log('Fetching metadata...');
    metadata.value = await getMetadata();
    console.log('Fetched metadata:', metadata.value);
  }
  metadata.value = await getMetadata();
  updateSharedData();
  // console.log('Updated Shared Data with Botanical Names:', tables.value.map(row => row[metadata.value.inferredColumnTitles.botanicalname]));
};

if(!props.testMode){
  const updateState = setupPanelWatchers(props.panel, props.panel_id, tool_id.value, tables, 'tables', saving, metadata)
  
  watch(() => updateState(), (newState) => {
    if (newState) {
      addPanelState(newState)
      // Update shared data when state changes
      updateSharedData()
    }
  }, { deep: true })
}

watch(tables, async (newTables) => {
  if (newTables == undefined) {
    push.error({ title: 'Error', message: "Couldn't read the plant schedule. Try again with a different file" })
  } else {
    loading.value = false
    // Update shared data when tables change
    await updateSharedData()
  }
})

// New watcher for updating available panels
watch([() => tables.value, () => metadata.value], ([newTables, newMetadata]) => {
  if (newTables && newMetadata) {
    nextTick(() => {
      setAvailablePanel({
        panel_id: props.panel_id,
        metadata: newMetadata,
        tool_uid: tool_id.value
      })
    })
  } else {
    nextTick(() => {
      removeAvailablePanel(props.panel_id)
    })
  }
}, { deep: true })

const { ctrl_z, escape } = useMagicKeys()

watch(ctrl_z, (pressed) => {
  if (pressed && tabulator.value) {
    try {
      tabulator.value.undo()
    } catch (e) {
      console.warn("can't undo", e)
    }
  }
})

watch(escape, (pressed) => {
  if (pressed) {
    console.log('escape pressed')
    try {
      isEditing.value = false
      showColumnStats.value = false
      showRowStats.value = false
    } catch (e) {
      console.warn("can't undo", e)
    }
  }
})

const colDiv = reactive({
  show: false,
  top: 0,
  left: 0,
  width: 0,
  height: 0
})

const cellDiv = reactive({
  show: false,
  top: 0,
  left: 0,
  width: 0,
  height: 0
})

const rowDiv = reactive({
  show: false, 
  top: 0,
  left: 0,
  width: 0,
  height: 0
})

const handleRowMouseOver = ({ event, rowData, boundingBox }) => {
}

const handleHeaderMouseOver = ({ event, column }) => {
  // console.log('header mouse over', event, column)
}

const scheduleOffsets = computed(() => {
  return {
    top: plantscheduleRef.value.getBoundingClientRect().top,
    left: plantscheduleRef.value.getBoundingClientRect().left,
  width: plantscheduleRef.value.getBoundingClientRect().width,
    height: plantscheduleRef.value.getBoundingClientRect().height
  }
})
const plantscheduleHeader = ref(null)

const tableHeight = ref(0)

const tableRect = ref(null)

const headerHeight = ref(null)
const cellData = ref(null)

const showColumnStats = ref(true)
const showRowStats = ref(true)
const columnStats = ref(null)
const columnStatsHeight = ref(0)
const lastCol = ref(false)
const isEditing = ref(false)

const columnData = ref(null)
const rowData = ref(null)

const columnTitle = ref('')


const handleCellMouseOver = ({ event, cell, boundingBox }) => {
  if (isEditing.value) {
    return
  }
  cellData.value = cell._cell.value
  rowData.value = cell._cell.row.getData()
  columnData.value = cell._cell.column.cells
    .map(cell => cell.value)
    .filter(value => value !== null && value !== undefined && value !== '');
  columnTitle.value = cell._cell.column?.definition?.title || ''
  
  
  let colElement = cell._cell.column.element.getBoundingClientRect()
  let firstCell = cell._cell.column.cells[0].getElement().getBoundingClientRect()
  let lastCell = cell._cell.column.cells[cell._cell.column.cells.length - 1].getElement().getBoundingClientRect()
  // console.log('lastCell', cell._cell.column.cells[cell._cell.column.cells.length - 1])
  let plantscheduleRefRect = plantscheduleRef.value.getBoundingClientRect()
  let cellElement = cell.getElement().getBoundingClientRect()
  let rowElement = cell._cell.row.getElement().getBoundingClientRect()
  let headerElement = cell._cell.column.parent.headersElement.getBoundingClientRect()
  
  headerHeight.value = headerElement.value?.height
  
  let plantscheduleHeaderElement = plantscheduleHeader.value.getBoundingClientRect()

  if (!cell._cell.column.field) {
    cell.getElement().classList.remove('hover')
    showColumnStats.value = false
  } else{
    showColumnStats.value = true
    showRowStats.value = true
  }
    
  if (enableHovers.value.column) {
    
      // loop over all cells and add a class to all of them
      cell._cell.column.cells.forEach((cell, index) => {
          cell.getElement().classList.add('hover')
      })
      
    
  }
  if (enableHovers.value.row) {
    // console.log(cell._cell.row)
    cell._cell.row.cells.forEach(cell => {
      cell.getElement().classList.add('hover')
    })
  }

  let convexBoundingRect = {
    x: lastCell.x,
    y: colElement.y,
    width: lastCell.x + lastCell.width - firstCell.x,
    height: ((lastCell.y) - (colElement.y))
  }


  let cellBoundingRect = {
    x: cellElement.x,
    y: cellElement.y,
    width: cellElement.width,
    height: cellElement.height
  }

  
  let hardCodedOffsets = {
    top: (-67 - headerElement.height),
    left: 5.5,
    width: 0,
    height: 0 + headerElement.height
  }

  let groupElement = cell._cell.column.groupElement.getBoundingClientRect()

  colDiv.show = true
  colDiv.top = 0
  colDiv.left = convexBoundingRect?.x + hardCodedOffsets.left - plantscheduleLeft.value + hardCodedOffsets.left
  colDiv.width = cellBoundingRect?.width + hardCodedOffsets.width
  colDiv.height = ((cell._cell.row.parent.displayRowsCount) * cell._cell.height)  
  

  cellDiv.show = true
  cellDiv.top = cellBoundingRect.y + hardCodedOffsets.top
  cellDiv.left = cell._cell.element.x + hardCodedOffsets.left
  cellDiv.width = cellBoundingRect.width + hardCodedOffsets.width
  cellDiv.height = groupElement.height

  rowDiv.show = true
  rowDiv.left = 0
  rowDiv.width = plantscheduleRefRect.width
  rowDiv.height = rowElement.height
  rowDiv.top = rowElement.top - scheduleOffsets.value.top + plantscheduleHeaderElement.height + 10
  rowDiv.bottom = rowElement.bottom

  
} 





watch(columnStats, (newVal) => {
  if(newVal){
    columnStatsHeight.value = newVal.getBoundingClientRect().height
  }
})

onClickOutside(columnStats, () => {
  if (isEditing.value) {
    // showColumnStats.value = false
    // showRowStats.value = false
  }
}, { ignore: ['.tabulator-cell', '.tabulator-table'] })


onClickOutside(plantscheduleRef, () => {
  if (isEditing.value) return
  showColumnStats.value = false
  showRowStats.value = false
}, { ignore: ['.tabulator-cell'] })

const handleTableMouseLeave = () => {
  // colDiv.show = false
}

const handleGroupMouseMove = ({ e, group }) => {
  if (isEditing.value) return
  showColumnStats.value = false
  showRowStats.value = false
}

const handleCellMouseLeave = ({ e, cell }) => {
  // loop over all cells and remove a class to all of them
  if (enableHovers.value.column) {
    cell._cell.column.cells.forEach(cell => {
      cell.getElement().classList.remove('hover')
    })
  }
  if(enableHovers.value.row){
    cell._cell.row.cells.forEach(cell => {
      cell.getElement().classList.remove('hover')
    })
  }
}


const handleCellEditing = ({ e, cell }) => {
// add class to cell
  // make cell width fit content
  // cell._cell.column.setWidth('300px')
  // cell._cell.table.redraw()
  // check what index cell is in cell._cell.row.cells
  
  lastCol.value = cell._cell.column.parent.columnsByIndex.indexOf(cell._cell.column) == cell._cell.column.parent.columnsByIndex.length - 1
  cellData.value = cell._cell.value

  cell._cell.table.element.classList.add('table-editing')
  cell._cell.getElement().classList.add('cell-editing')
  isEditing.value = true
  // Move caret to the start of the input
  nextTick(() => {
    const input = cell.getElement().querySelector('input')
    if (input) {
      input.select()
      // input.setSelectionRange(0, 0)
      input.setAttribute('spellcheck', 'false')
    }
  })
}

const handleCellEditCancelled = ({ e, cell }) => {
  
  cell._cell.table.element.classList.remove('table-editing')
  cell._cell.getElement().classList.remove('cell-editing')
  isEditingFalseOnMouseOut.value = true
  // isEditing.value = false
}

const handleCellEdited = ({ e, cell }) => {
  cell._cell.table.element.classList.remove('table-editing')
  cell._cell.getElement().classList.remove('cell-editing')
  // showColumnStats.value = false
  isEditing.value = false
  //redraw the column
  // cell._cell.column.redraw()
}

// watchDebounced(plantscheduleWidth, (newWidth) => {
// watchDebounced(plantscheduleWidth, (newWidth) => {
//   if(tables.value?.columns){
//     tables.value.columns = newWidth < 640
//   }
// }, { immediate: true, debounce: 50 })




const handleRowAdded = (rowData) => {
  console.log('New row added:', rowData);
};


// Use useMagicKeys to detect spacebar press
const { space } = useMagicKeys()

// Hide stats when spacebar is pressed
whenever(space, () => {
  // prevent default
     
  showColumnStats.value = false
  showRowStats.value = false
})

const invert = obj => Object.fromEntries(Object.entries(obj).map(a => a.reverse()))

const inferColumnTypes = async () => {
  if (!tables.value || !tables.value.columns) {
    console.warn('Tables or columns are not available yet');
    return {};
  }

  let oldTitles = JSON.stringify(tables.value.columns.map(column => column.title))
  try {
    const response = await chatLLM(`You are an expert landscape architect, Give the following list of plant schedule table column headings, match them with their canonical labels. You must only respond in json by mapping each given column title as key, and value set to the closest matching canonical value, leave empty if none found. duplicate columns and values are allowed. Do not respond with any other text just speak json. List of canonical values: ['qty', 'botanicalname', 'commonname', 'maturesize', 'maturewidth', 'matureheight', 'potsize', 'spacing', 'density', 'code', 'width', 'height', 'spread', 'proportionpercentage'] ${oldTitles}`)

    // Clean up the response
    const cleanedResponse = response
      .replace(/\n/g, '') // Remove newlines
      .replace(/\\/g, '') // Remove backslashes
      .replace(/^"|"$/g, '') // Remove leading and trailing quotes if present

    return {inferredColumnTitles: JSON.parse(cleanedResponse), columnToCanonical: invert(JSON.parse(cleanedResponse))}  
  } catch (error) {
    console.error('Error inferring column types', error)
    return {} // Return an empty object in case of error
  }
}

const heightValues = ref([])
const widthValues = ref([])

const getMetadata = async () => {
  const metadata = props.panel?.data?.output?.metadata || panelState.value[props.panel_id]?.output?.metadata || {};
  const result = { ...metadata };

  if (!result.title) {
    let commonNames = tables.value?.rows?.map(row => row.col2).filter(Boolean) || [];
    if (commonNames.length > 0) {
      const commonNamesString = JSON.stringify(commonNames.slice(0, 10)); // Limit to first 10 for brevity
      result.title = await chatLLM(`You are an expert landscape architect. Given the following list of common name plants from planting pallet, think of the most relevant but extremely specific documentation package title or project name. Something sensible, not too much flourish or creativity. You must provide two or three word title and say nothing other than those words in your response (stripped of quotation marks, hyphens). Pallet: ${commonNamesString}`);
    } else {
      result.title = "Plant Schedule";
    }
  }

  if (!result.inferredColumnTitles && tables.value && tables.value.columns) {
    let {inferredColumnTitles, columnToCanonical} = await inferColumnTypes()
    result.inferredColumnTitles = inferredColumnTitles
    result.columnToCanonical = columnToCanonical
  }
  // Identify mature height and width columns
  // console.log('result.inerredColumns result', result.inferredColumnTitles)
  // console.log('tables.value', tables.value)
  // console.log('result.inferredColumnTitles[column.title]', result.inferredColumnTitles)
  // console.log('found it?', tables.value?.columns.map(column => column.title.contains('Mature')))
  const heightKey = Object.keys(result.inferredColumnTitles).find(key => result.inferredColumnTitles[key] === 'matureheight')
  const widthKey = Object.keys(result.inferredColumnTitles).find(key => result.inferredColumnTitles[key] === 'maturewidth')
  const heightColumn = tables.value?.columns?.find(column => column.title.includes(heightKey));
  heightValues.value = tables.value?.rows.map(row => row[heightColumn?.field])
  const widthColumn = tables.value?.columns?.find(column => column.title.includes(widthKey));
  widthValues.value = tables.value?.rows.map(row => row[widthColumn?.field])
  

  if (heightColumn && widthColumn) {
      console.log(`Separate height (${heightColumn}) and width (${widthColumn}) columns detected`);
    }

  if (!result.fileMetadata) {
    result.fileMetadata = props?.panel?.data?.fileMetadata || {};
  }

  console.log('inferredColumns result', result)
  return result;
}

// Initial data fetch
onMounted(async () => {
  await updateSharedData()
  
  await refreshPanel()
})






// Content type mapping
const contentTypeToExtension = ref({
  'application/pdf': { label: 'PDF', iconLarge: 'vscode-icons:file-type-pdf2', iconSmall: 'ph:file-pdf-fill' },
  'application/msword': { label: 'Word Document', iconLarge: 'vscode-icons:file-type-word', iconSmall: '' },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { label: 'Word Document', iconLarge: 'vscode-icons:file-type-word', iconSmall: '' },
  'application/vnd.ms-excel': { label: 'Excel Document', iconLarge: 'vscode-icons:file-type-excel', iconSmall: '' },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { label: 'Excel Document', iconLarge: 'vscode-icons:file-type-powerpoint', iconSmall: '' },
  'application/vnd.ms-powerpoint': { label: 'PowerPoint', iconLarge: 'vscode-icons:file-type-powerpoint', iconSmall: '' },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': { label: 'PowerPoint', iconLarge: 'vscode-icons:file-type-powerpoint', iconSmall: '' },
  'text/plain': { label: 'Text file', iconLarge: 'hugeicons:txt-02', iconSmall: '' },
})

const editing = ref(false)

const handleUpdateTitle = async (newTitle) => {
  metadata.value.title = newTitle
  // Update the panelState
  addPanelState({
    [props.panel_id]: {
      ...panelState.value[props.panel_id],
      title: newTitle,
      output: {
        ...panelState.value[props.panel_id]?.output,
        metadata: {
          ...panelState.value[props.panel_id]?.output?.metadata,
          title: newTitle
        }
      }
    }
  });

  editing.value = false
}


const handleMouseLeaveColumnStats = () => {
  if (isEditingFalseOnMouseOut.value) {
    isEditing.value = false
    isEditingFalseOnMouseOut.value = false
    showColumnStats.value = false
    showRowStats.value = false
  }
}

const handleMouseLeaveRowStats = () => {
  if (isEditingFalseOnMouseOut.value) {
    isEditing.value = false
    isEditingFalseOnMouseOut.value = false
    showColumnStats.value = false
    showRowStats.value = false
  }
}

const addRow = () => {
  if (tabulator.value) {
    tabulator.value.addNewRow(); // Add to top of table
  }
};

const handleColumnsUpdate = (newColumns) => {
  // updatePanelColumns(props.panel_id, newColumns);
  // // Ensure the local state is updated as well
  // if (tables.value) {
  //   tables.value.columns = newColumns;
  // }
};

const handleRowsUpdate = (newRows) => {
  // updatePanelRows(props.panel_id, newRows);
  // // Ensure the local state is updated as well
  // if (tables.value) {
  //   tables.value.rows = newRows;
  // }
};


// Assuming you have access to the project data, if not, you'll need to fetch it
const project = computed(() => props.panel?.data?.project || {})

// Fetch the project image URL
const projectImageUrl = ref('')

const base_url = useRuntimeConfig().public.imagekit_base_url

onMounted(async () => {
  
  if (selectedProject.value) {
    const imageUrl = await getImageForProject(selectedProject.value.title)
    projectImageUrl.value = imageUrl ? `${base_url}${imageUrl}` : ''
  }
})

</script>

<template>
<div 
  class="absolute pointer-events-none w-full h-20 top-0 left-0 z-0 bg-gradient-to-t from-background to-transparent dark:mix-blend-soft-light"
  :style="{ 
    backgroundImage: projectImageUrl ? `url(${projectImageUrl})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }"
>
  <div class="absolute inset-0 bg-gradient-to-t from-background to-transparent"></div>
</div>
  <div ref="plantscheduleRef"
    class="w-full h-[95%] flex flex-col justify-start items-start overflow-hidden pr-1 sm:pr-4 pt-0 mt-0 align-start mr-[-5px] sm:pl-2 mt-2">

    <div v-if="loading" class="absolute z-10 w-full h-full flex pointer-events-none justify-center ">
      <Loader2 class="w-8 h-8 mr-2 animate-spin self-center" />
    </div>

    <div ref="plantscheduleHeader" class="w-full flex flex-col justify-start items-start mb-4 relative z-10">
      <div class="flex justify-between items-start w-full">
        <div class="flex flex-col items-start">
          <h3 v-if="metadata" class="text-2xl font-bold sm:p-3 w-full">
            <InlineEdit v-if="editing" :modelValue="metadata?.title" @update:modelValue="handleUpdateTitle"
              @edit="editing = $event" :scale="1.2" preview-class="text-muted-foreground text-4xl"
              input-class="pointer-events-auto text-4xl" :buttons-width="90" save-icon="lucide:check" cancel-icon="lucide:x"
              :maxInputWidth="300" />
            <span v-else @click="editing = true" class="cursor-pointer block w-full overflow-visible whitespace-normal">
              {{ metadata?.title }}
            </span>
          </h3>
          <p v-if="metadata?.fileMetadata?.filename"
            class="text-xs font-semibold text-muted-foreground space-x-2 py-1 ml-3">
            <Icon :name="contentTypeToExtension[metadata?.fileMetadata?.content_type]?.iconSmall" class="w-4 h-4 inline-block" />
            <span class="bg-muted p-1 rounded-md">{{ metadata?.fileMetadata?.filename }}</span>
          </p>
        </div>
        <div class="flex items-center justify-end gap-2 opacity-75 scale-75 origin-top-right">
          <CycleButton v-model:enableHovers="enableHovers" />
          <TooltipProvider :delayDuration="200" :skipDelayDuration="700">
            <Tooltip>
              <TooltipTrigger>
                <Button v-if="tables" class="p-2 aspect-square" variant="outline" @click="downloadAs('xlsx')">
                  <Icon name="mdi:google-spreadsheet" class="w-6 h-6 p-0" />
                </Button>
              </TooltipTrigger>
              <TooltipContent
                class="pointer-events-all bg-primary text-background shadow-xxl !animate-none select-none border-none">
                <p>Download as XLSX</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>

    <ClientOnly>

      <HoverCard v-if="colDiv && enableHovers.column && columnData" :open="showColumnStats" :defaultOpen="false"
        :openDelay="0" :closeDelay="0">
        <HoverCardTrigger as-child>
          <div class="absolute flex flex-row pointer-events-none" :style="{
            top: `${colDiv.top}px`,
            left: `${colDiv.left}px`,
            width: `${colDiv.width}px`,
            height: `${colDiv.height}px`
          }">
            <!-- <PlantScheduleColumnStats :width="colDiv.width" :overflowing="colDiv.overflowing" :tableRect="tableRect" :colRect="colDiv" :panelEdgeX="{right: plantscheduleRef.getBoundingClientRect().right, left: plantscheduleRef.getBoundingClientRect().left}" />   -->
          </div>
          <!-- Your trigger element here -->
        </HoverCardTrigger>

        <HoverCardContent :avoidCollisions="true" :collisionBoundary="plantscheduleRef" :prioritizePosition="false"
          sticky="always" updatePositionStrategy="always" align="start" side="right"
          :collisionPadding="{ top: 60, bottom: 0 }" :alignOffset="0" :sideOffset="0" :hideWhenDetached="true"
          class="w-[30vw] h-fit bg-background HoverCardContent flex transition-all "
          :class="{ 'tabulator-editing ml-[120px]': isEditing }">
          <div ref="columnStats" @mouseleave="handleMouseLeaveColumnStats" :style="{ paddingBottom: '20px' }" o
            class="border-primary/60 border-2 w-full h-full opacity-[0.8] rounded-lg row-stats ">

            <ColumnStatsPanel
              :botanicalnames="tables.rows.map(row => row[Object.keys(metadata?.inferredColumnTitles || {}).find(key => metadata?.inferredColumnTitles?.[key] === 'botanicalname') || row['Botanical Name'] || row['Botanical Names'] || row['col1'] || ''])"
              :heightValues="heightValues"
              :widthValues="widthValues"
              :metadata="{...metadata, panel_id}" :title="columnTitle":col="columnData" :row="rowData"
              :cell="cellData" />
              
          

            <!-- <div v-if="true" class="w-64 absolute top-0 -left-128 bg-background"
              :style="{ height: colDiv.height + 'px', maxHeight: 'var(--radix-hover-card-content-available-height)' }">
              asdf
            </div> -->
          </div>
        </HoverCardContent>


      </HoverCard>

      <HoverCard v-if="rowDiv && enableHovers.row && columnData" :open="showRowStats" :defaultOpen="false"
        :openDelay="0" :closeDelay="0">
        <HoverCardTrigger as-child>
          <div class="absolute flex flex-row pointer-events-none h-0" :style="{
            top: `${rowDiv.top}px`,
            left: `${rowDiv.left}px`,
            width: `${plantscheduleWidth}px`,
            height: `${rowDiv.height}px`
          }">
            
          </div>
          <!-- Your trigger element here -->
        </HoverCardTrigger>

        <HoverCardContent :avoidCollisions="true" :collisionBoundary="plantscheduleRef" :prioritizePosition="false"
          sticky="always" updatePositionStrategy="always" align="center" side="bottom" :collisionPadding="0"
          :alignOffset="0" :sideOffset="0" :hideWhenDetached="true"
          class="w-fit h-[100vh] bg-transparent HoverCardContent pointer-events-none flex transition-all py-0 my-0"
          :class="{ 'tabulator-editing mt-8': isEditing }">
          <div ref="rowStats" @mouseleave="handleMouseLeaveRowStats"
            :style="{ paddingBottom: '20px', width: `${plantscheduleWidth-10}px` }"
            class="w-full bg-transparent h-fit pointer-events-none opacity-[0.8] row-stats ">
            
            <RowStatsPanel :col="columnData" :row="rowData" :cell="cellData"
            :metadata="{...metadata, panel_id}" :title="columnTitle"
            :species="rowData[metadata?.columnToCanonical['botanicalname']]"
            :botanicalnames="tables.rows.map(row => row[Object.keys(metadata?.inferredColumnTitles || {}).find(key => metadata?.inferredColumnTitles?.[key] === 'botanicalname') || row['Botanical Name'] || row['Botanical Names'] || row['col1'] || ''])"
             />
            



          </div>
        </HoverCardContent>


      </HoverCard>
      <div ref="plantscheduleRef" v-if="tables" class="w-full h-full align-start items-start">

        <TabulatorTable v-if="tables.rows" ref="tabulator" @click="handleClick" :rows="tables.rows"
          class="is-bordered is-narrow overflow-hidden" :movableColumns="true" :columns="tables.columns" :options="{
            groupBy: '_group',
            height:'100%',
            // layout: 'fitDataFill',
            selectableRows: true,
            selectableRowsRangeMode: 'click',
            history: true,
            movableColumns: true,
            responsiveLayout: 'collapse'
          }" :metadata="metadata" @row-mouseover="handleRowMouseOver" @headerMouseOver="handleHeaderMouseOver"
          @cellMouseOver="handleCellMouseOver" @table-height="tableHeight=$event" @table-rect="tableRect=$event"
          @groupMouseMove="handleGroupMouseMove" @cellMouseLeave="handleCellMouseLeave" @cellEditing="handleCellEditing"
          @cellEditCancelled="handleCellEditCancelled" @cellEdited="handleCellEdited" @row-added="handleRowAdded"
          @update:columns="handleColumnsUpdate" @update:rows="handleRowsUpdate" />
        <!-- <Button @click="addRow">Add Row</Button> -->



      </div>
      <div v-if="!tables && !loading"
        class="flex flex-col justify-center items-center space-y-2 w-full h-full align-center text-center">
        <Icon name="solar:sad-square-outline" class="w-10 h-10" />
        <div class="text-sm w-[600px]">No data available.<br />Please check your data source.</div>
        <Button variant="outline" @click="">Reload</Button>
      </div>


      <!-- <BorderBeam style="pointer-events: none !important" v-if="includeDataInChat && activePanel === panel_id" borderRadius="10px" :anchor="90" :size="beamSize" /> -->
      <GodRays class="-mt-2 w-[calc(100%-9px)] h-[calc(100%-12px)] rounded-b-lg pointer-events-none mix-blend-multiply" :colors="['#f97316', '#fd7e14', '#e44c65', '#c026d3', '#a855f7']" v-if="includeDataInChat && activePanel === panel_id && chatDialogOpen"   />
      <!-- <div v-if="showColumnStats" v-motion-fade
        class="absolute column-stats z-[1000] flex flex-row bg-accent mix-blend-multiply rounded-xl pointer-events-none constrainHeightToPopover"
        :style="{
        top: `${colDiv.top}px`,
        left: `${colDiv.left}px`,
        width: `${colDiv.width}px`,
        height: `${colDiv.height}px`
      }"></div> -->
    </ClientOnly>
  </div>

  <!-- <div :class="`absolute -bottom-4 left-0 bg-transparent w-full h-[${plantscheduleHeight * .1}px]`">
    <Accordion type="single" collapsible class="no-chevron">
      <AccordionItem value="debug">
        <AccordionTrigger class="max-w-fit hover:no-underline">
          <Icon name="solar:code-bold-duotone" class="w-4 h-4 mx-2 mb-2 bg-background rounded-full" />
          <span class="text-xs mb-1 text-primary/20">{{ panel_id }}</span>
        </AccordionTrigger>
        <AccordionContent>
          <ScrollArea :class="`w-full bg-muted p-10 h-[80vh] overflow-hidden`">
            <pre class="text-xs text-wrap">{{ panel?.data?.output }}</pre>
            <pre class="text-xs text-wrap">{{ tables }}</pre>
          </ScrollArea>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  </div> -->

</template>

<style lang="scss">
.tabulator.is-bordered {
  border: none !important;
}



.tabulator-editing .tabulator-cell:not(.cell-editing) {
  opacity: 0.3 !important;
  pointer-events: none;
}
.tabulator-editing .tabulator-cell:not(.cell-editing) > *:not(.HoverCardContent) {
  pointer-events: none;
}

.cell-editing {
  opacity: 1 !important;
  // padding: 3px;
  // z-index: 1000 !important;
  width: 130px !important;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  // border: 2px solid hsl(var(--primary)) !important;
  box-sizing: content-box !important;
  border-radius: 5px;
  background-color: hsl(var(--background));
  overflow: visible !important;
  pointer-events: all !important;
  transform: scale(1.25);
  transform-origin: center left;
  margin: 0 10px !important;
  z-index: 1000 !important;

  &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border: 1px solid hsl(var(--primary));
      border-radius: 5px;
      // pointer-events: none;
    }
  // box-shadow:
  //   1.7px 1.5px 2.4px rgba(0, 0, 0, 0.073),
  //   4px 3.6px 5.8px rgba(0, 0, 0, 0.105),
  //   7.5px 6.8px 10.9px rgba(0, 0, 0, 0.13),
  //   13.4px 12.1px 19.4px rgba(0, 0, 0, 0.155),
  //   25.1px 22.6px 36.3px rgba(0, 0, 0, 0.187),
  //   60px 54px 87px rgba(0, 0, 0, 0.26);
}

.cell-editing * {
  opacity: 1 !important;
}

/* Ensure HoverCard and its content remain fully opaque */
.HoverCardContent,
.HoverCardContent * {
  box-shadow: none !important;
  opacity: 1 !important;
}

@import '~/assets/css/tabulator_style.css';

.no-chevron svg {
  display: none;
}

.constrainHeightToPopover{
  max-height: var(--radix-hover-card-content-available-height) !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(1) !important;
  }
  to {
    opacity: 1;
    transform: scale(1) !important;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.HoverCardContent {
  animation: fadeIn 80ms ease-out !important;
  border: none !important;
  padding: 0 !important;
}

.HoverCardContent[data-state="closed"] {
  animation: fadeOut 180ms ease-out !important;
}

.cell-editing{
  box-shadow:
      1.7px 1.5px 2.4px rgba(0, 0, 0, 0.073),
      4px 3.6px 5.8px rgba(0, 0, 0, 0.105),
      7.5px 6.8px 10.9px rgba(0, 0, 0, 0.13),
      13.4px 12.1px 19.4px rgba(0, 0, 0, 0.155),
      25.1px 22.6px 36.3px rgba(0, 0, 0, 0.187),
      60px 54px 87px rgba(0, 0, 0, 0.26);
}

.column-stats, .row-stats{
  animation: fadeIn 0.5s ease-out !important;
    // box-shadow: 7.4px 0px 3.2px -8px rgba(0, 0, 0, 0.036),
    //   -7.4px 0px 3.2px -8px rgba(0, 0, 0, 0.036),
    //   17.2px 0px 7.5px -8px rgba(0, 0, 0, 0.052),
    //   -17.2px 0px 7.5px -8px rgba(0, 0, 0, 0.052),
    //   30.9px 0px 13.4px -8px rgba(0, 0, 0, 0.064),
    //   -30.9px 0px 13.4px -8px rgba(0, 0, 0, 0.064),
    //   51.2px 0px 22.3px -8px rgba(0, 0, 0, 0.076),
    //   -51.2px 0px 22.3px -8px rgba(0, 0, 0, 0.076),
    //   84.4px 0px 36.8px -8px rgba(0, 0, 0, 0.088),
    //   -84.4px 0px 36.8px -8px rgba(0, 0, 0, 0.088),
    //   147.5px 0px 64.3px -8px rgba(0, 0, 0, 0.104),
    //   -147.5px 0px 64.3px -8px rgba(0, 0, 0, 0.104),
    //   319px 0px 139px -8px rgba(0, 0, 0, 0.14),
    //   -319px 0px 139px -8px rgba(0, 0, 0, 0.14);
}


div:has(div.HoverCardContent:not(.tabulator-editing)){
  pointer-events: none !important;
}
div:has(div.HoverCardContent.tabulator-editing){
  pointer-events: all !important;
}



.tabulator-cell.hover:not(.cell-editing){
  background-color:  hsl(var(--muted-foreground) / 1);
  color: hsl(var(--background));
  border: none !important;
}

.tabulator-cell.hover:not(.cell-editing):not(:empty):hover {
  background-color: hsl(var(--accent));
  color: hsl(var(--primary));
}

.tabulator-cell.disable-hover{
  background-color:  unset !important;
  color: unset !important;
  border: none !important;
}

.truncate {
  white-space: normal;
  overflow: visible;
}
</style>
<script setup lang="ts">
import type { VChart } from '#components'
import { use } from 'echarts/core'
import { ToolboxComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'


use([ToolboxComponent, BarChart])

const props = defineProps<{
    data: (number | string)[] | null | undefined
    cell: number | string
    cm: string
    metadata: any  // Add this prop
    columnTitle: string
    botanicalnames: string[]  
}>()

// console.log(props.botanicalnames, props.metadata.inferredColumnTitles)
const chart = ref<InstanceType<typeof VChart> | null>(null)
const chartContainer = ref(null)

const initOptions = ref({
    renderer: 'svg'
})


const colors = computed(() => {
    if (props.cm === 'dark') {
        return {
            mutedForeground: 'hsl(90 20.2% 65.1%)',
            accent: 'hsl(160 32.6% 21.5%)',
            muted: 'hsl(160 22.6% 13.5%)',
            primary: 'hsl(90 20% 84%)'
        }
    } else {
        return {
            mutedForeground: 'hsl(160 21% 36.9%)',
            accent: 'hsl(160 30% 79.1%)',
            muted: 'hsl(90 11% 91%)',
            primary: 'hsl(120 27.4% 21.2%)'
        }
    }
})



const option = ref(null)

const qtyColumn = computed(() => {
  return props.metadata?.inferredColumnTitles?.[props.columnTitle] === 'qty'
})

// console.log('qtyColumn', qtyColumn.value==true)

const createHistogram = (data: (number | string)[], botanicalnames: string[]): { bins: { label: string, count: number }[], counts: number[] } | null => {
    if (!data || data.length === 0 || !botanicalnames || botanicalnames.length === 0) {
        return null
    }

    if (qtyColumn.value) {
        // Special handling for 'qty' column
        const qtyMap = new Map<string, number>()
        data.forEach((value, index) => {
            if (value !== '' && value !== null && value !== undefined) {
                // Remove commas and convert to number
                const qty = Number(String(value).replace(/,/g, ''))
                const label = botanicalnames[index] || `Plant ${index + 1}`
                qtyMap.set(label, qty)
            }
        })

        const bins = Array.from(qtyMap.entries())
            .map(([label, count]) => ({ label, count }))
            .sort((a, b) => b.count - a.count)

        const counts = bins.map(bin => bin.count)

        return { bins, counts }
    } else {
        // Existing logic for other columns
        const countMap = new Map<string, number>()

        data.filter(value => value !== '' && value !== null && value !== undefined)
            .forEach(value => {
                const key = String(value)
                countMap.set(key, (countMap.get(key) || 0) + 1)
            })

        const bins = Array.from(countMap.entries())
            .map(([label, count]) => ({ label, count }))
            .sort((a, b) => b.count - a.count)

        const counts = bins.map(bin => bin.count)

        return { bins, counts }
    }
}



watch([() => props.data, colors, () => props.metadata], ([newData, newColors, newMetadata]) => {
    const inferredColumnTitles = newMetadata?.inferredColumnTitles || {}
    const inferredColumnTitle = inferredColumnTitles[props.metadata.title] || ''
    // console.log('botanicalnames', props.botanicalnames)
    // console.log('inferredColumnTitle', inferredColumnTitles)
    // console.log('qtyColumn', qtyColumn.value)
    const histogramData = createHistogram(newData || [], props.botanicalnames || [])

    if (!histogramData) {
        option.value = null
        return
    }

    const bins = histogramData.bins
    const labelThreshold = 20
    const showAllLabels = bins.length <= labelThreshold

    option.value = {
        animationDuration: 0,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: (params: any) => {
                const bin = histogramData.bins[params[0].dataIndex]
                return `${bin.label}`
            }
        },
        grid: {
            left: '10%',
            right: '5%',
            bottom: '15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: bins.map(bin => bin.label),
            axisLabel: {
                rotate: 45,
                interval: showAllLabels ? 0 : 1,
                formatter: (value: string, index: number) => {
                    if (qtyColumn.value==true) {
                        return value  // For 'qty', show all labels
                    }
                    if (showAllLabels || index % 2 === 0) {
                        return value
                    }
                    return ''
                },
                color: newColors.mutedForeground,
                overflow: 'breakAll',
                align: 'right',
                verticalAlign: 'middle'
            },
            axisLine: {
                lineStyle: {
                    color: newColors.muted
                }
            },
            splitLine: {
                lineStyle: {
                    color: newColors.muted
                }
            }
        },
        yAxis: {
            type: 'value',
            name: qtyColumn.value==true ? 'Quantity' : '',  // Add label for 'qty' column
            nameLocation: 'middle',
            nameGap: 50,
            axisLabel: {
                color: newColors.mutedForeground
            },
            axisLine: {
                lineStyle: {
                    color: newColors.muted
                }
            },
            splitLine: {
                lineStyle: {
                    color: newColors.muted
                }
            }
        },
        series: [
            {
                name: qtyColumn.value==true ? 'Quantity' : 'Histogram',
                type: 'bar',
                data: bins.map(bin => ({
                    value: bin.count,
                    itemStyle: {
                        color: String(bin.label) === String(props.cell)
                            ? newColors.accent
                            : newColors.mutedForeground
                    }
                })),
                emphasis: {
                    itemStyle: {
                        color: newColors.accent
                    }
                }
            }
        ],
        textStyle: {
            color: newColors.primary
        },
        title: {
            text: '',
            left: 'center'
        }
    }
}, { immediate: true })

const hasValidData = ref(false)

watch(() => option.value, (newOption) => {
    hasValidData.value = newOption !== null
})

// Function to convert HSL to RGB
function hslToRgb(hsl) {
    const [h, s, l] = hsl.split(' ').map(Number)
    const a = s * Math.min(l, 1 - l)
    const f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return `rgb(${Math.round(f(0) * 255)}, ${Math.round(f(8) * 255)}, ${Math.round(f(4) * 255)})`
}

// Function to get RGB color from CSS variable
function getRGBColor(variable) {
    const style = getComputedStyle(document.documentElement)
    const color = style.getPropertyValue(variable).trim()
    return hslToRgb(color)
}

</script>

<template>
    <p class="text-xs pl-12 -mb-8">{{ qtyColumn ? 'Distribution of' : 'Values in' }} <Badge
            class="bg-muted-foreground/10 text-muted-foreground text-xs py-0 px-1 font-normal">{{
                props.columnTitle }}</Badge>
    </p>
    

    <div ref="chartContainer" class="chart-container p-0 m-0">
        <VChart ref="chart" :option="option" :init-options="initOptions" :autoresize="true" />
        <!-- <div v-else class="no-data-message">No valid data available for visualization</div> -->
    </div>
</template>

<style scoped>
.chart-container {
    width: 100%;
    height: 400px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-data-message {
    font-size: 16px;
    color: v-bind('colors.primary');
}
</style>
import { ref, reactive } from 'vue';

import { MyMap, FileUpload, PlantSchedule, Gallery, Review, PdfViewerPanel, VisualisePanel, CommunityTool, Discovery } from "#components";
import { v4 as uuidv4 } from "uuid";

export function useTools() {
  const uiStore = useUiStore()
  const { toggleChatDialog, togglePinChatDialog, toggleCommandDialog, toggleBottomSheet, setBottomSheetDynamicComponent } = uiStore
  const { chatDialogOpen, chatDialogPinned } = storeToRefs(uiStore);

  const dockStore = useDockStore()
  const {addPendingPanel, addPanelState, setPanelId_toolUid } = dockStore;
  
  const tools = reactive([
    {
      uid: "2d06ebb1-9a56-46f0-83ac-910c6974a80b",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "",
      location: "toolbar",
      id: "discovery",
      label: "Discover Plants",
      tooltip: "Discover Plants",
      icon: "streamline:nature-ecology-tree-2-tree-plant-circle-round-park",
      action: () =>
        addPanel("discovery", {}),
      component: null,
      componentName: "Discovery",
      connectionsAllowed: ["gallery"],
      connectionsDefault: ["gallery"],
    },
    {
      uid: "28840ba0-1bd2-4ceb-93fa-322131ad1659",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/map",
      location: "toolbar",
      id: "map",
      label: "Map",
      tooltip: "Add Map",
      icon: "carbon:earth-filled",
      action: () =>
        addPanel("map", { center: [12.550343, 55.665957], zoom: 8 }),
      component: null,
      componentName: "MyMap",
      connectionsAllowed: ["schedule", "visualise"],
      connectionsDefault: ["gallery"],
    },
    {
      uid: "49cff42f-c0a8-44cb-9f8a-85012507ba5e",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/optimise",
      location: "toolbar",
      id: "optimise",
      label: "Optimise",
      tooltip: "Optimise planting pallet",
      icon: "lucide:scatter-chart",
      action: () => {},
      component: null,
      componentName: "",
      connectionsAllowed: ["schedule"],
      connectionsDefault: ["schedule"],
    },
    {
      uid: "5b1d91fd-39ae-4ff4-849e-2508541fb1e3",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/plants",
      location: "toolbar",
      id: "plants",
      label: "Plants",
      tooltip: "Add plants",
      icon: "ph:plant",
      action: () => {
        toggleCommandDialog(true);
      },
      component: null,
      componentName: "",
      connectionsAllowed: [],
      connectionsDefault: [],
    },
    {
      uid: "4447bd6c-9ade-47c3-891b-2d88576a2ec7",
      inUse: false,
      buttonType: "Button",
      description: "Add your plant schedule",
      apiRoute: "/api/tool/parseSchedule",
      location: "toolbar",
      id: "schedule",
      label: "Schedule",
      tooltip: "Plant Schedule",
      icon: "material-symbols:rule-rounded",
      action: () => {},
      component: PlantSchedule,
      componentName: "PlantSchedule",
      connectionsAllowed: [
        "map",
        "optimise",
        "visualise",
        "gallery",
        "review",
        "pdfviewer",
      ],
      connectionsDefault: ["review"],
    },
    {
      uid: "f82b9927-7d8a-45e4-85df-1ced6b82af8b",
      inUse: chatDialogOpen.value,
      buttonType: "Toggle",
      description: "",
      apiRoute: "/api/tool/chat",
      location: "toolbar",
      id: "chat",
      label: "Chat",
      tooltip: "Chat",
      icon: "solar:chat-line-linear",
      component: null,
      componentName: "",
      connectionsAllowed: [],
      connectionsDefault: [],
      action: () => {
        toggleChatDialog();
        tools.find((tool) => tool.id === "chat").inUse = chatDialogOpen.value;
      },
    },
    {
      uid: "392216f6-c82d-4950-81dc-3188cdaa69cc",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/visualise",
      location: "toolbar",
      id: "visualise",
      label: "Visualise",
      tooltip: "Visualise",
      icon: "uim:scenery",
      action: () => {},
      component: VisualisePanel,
      componentName: "VisualisePanel",
      connectionsAllowed: ["schedule", "map"],
      connectionsDefault: ["map"],
    },
    {
      uid: "1ee002ac-ba72-45be-9441-2cf2c7f9d22b",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/gallery",
      location: "toolbar",
      id: "gallery",
      label: "Image",
      tooltip: "Gallery",
      icon: "solar:gallery-wide-bold",
      action: () => {
        addPanel("gallery", { images: [] });
      },
      component: Gallery,
      componentName: "Gallery",
      connectionsAllowed: ["schedule", "map"],
      connectionsDefault: ["map"],
    },
    {
      uid: "6a337643-2770-4cfe-8557-7c8409bdea7e",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/review",
      location: "toolbar",
      id: "review",
      label: "Review",
      tooltip: "Review your project",
      icon: "material-symbols-light:planner-review-rounded",
      action: () => {},
      component: Review,
      componentName: "Review",
      connectionsAllowed: ["schedule"],
      connectionsDefault: ["schedule"],
    },

    {
      uid: "8b6ee2d8-ef11-462a-a6c8-b31b6bd21424",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/pdfviewer",
      location: "toolbar",
      id: "pdfviewer",
      label: "PDF",
      tooltip: "View PDF",
      icon: "ph:file-pdf-light",
      action: () => {
        addPanel("pdfviewer", {
          url: "https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf",
        });
      },
      component: PdfViewerPanel,
      componentName: "PdfViewerPanel",
      connectionsAllowed: ["schedule"],
      connectionsDefault: ["schedule"],
    },
    {
      uid: "34f07a70-3633-4408-bda9-ebf80dc01231",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/community",
      location: "toolbar",
      id: "community",
      label: "Community",
      tooltip: "Community",
      icon: "material-symbols-light:hive-outline-rounded",
      action: () => {
        setBottomSheetDynamicComponent(markRaw(CommunityTool));
        toggleBottomSheet(true);
      },
      component: CommunityTool,
      componentName: "CommunityTool",
      connectionsAllowed: [],
      connectionsDefault: [],
    },
  ]);

  const docTypeToolMap: { [key: string]: string[] } = {
    'plantImage': ['gallery'],
    'PlantSchedule': [ 'visualise', 'map', 'schedule'],
    'report': ['schedule', 'pdfviewer'],
    'pdfviewer': ['pdfviewer'],
    'CAD': ['schedule'],
    'publication': ['schedule']

    // 'PlantSchedule': ['schedule', 'map', 'optimise', 'plants', 'chat', 'visualise'],
    // 'report': ['schedule', 'chat'],
    // 'CAD': ['map', 'visualise'],
    // 'publication': ['chat']
  }

  const initiateChat = () => {
    toggleChatDialog(true);
  }

  const addPanel = (toolId: string, panelData: any) => {
    const tool = tools.find(t => t.id === toolId);
    if (!tool) return;

    const uuid = uuidv4();
    console.log(`adding ${toolId}`, uuid);

    const panelState = {
      [uuid]: {
        title: tool.label,
        label: tool.label,
        tooltip: `${uuid}`,
        tool_uid: tool.uid,
        data: { output: panelData },
        iconSmall: tool.icon,
        closeType: "unSave",
      },
    };

    addPanelState(panelState);
    setPanelId_toolUid({ [uuid]: tool.uid });

    addPendingPanel({
      panel_id: uuid,
      data: { output: panelData },
      tool_uid: tool.uid,
      fileAnalysis: { output: panelData, tool_uid: tool.uid },
      analysisResult: { data: { output: panelData }, tool_uid: tool.uid },
      reuse: false,
    });
  };

  // const addGallery = () => addPanel('gallery', { images: [] });
  // const addMap = () => addPanel('map', { center: [12.550343, 55.665957], zoom: 8 });
  
  const inferTool = (fileMetadata: any) => {
    const extensionMap: { [key: string]: string[] } = {
      png: ["plantImage"],
      jpg: ["plantImage"],
      jpeg: ["plantImage"],
      xlsx: ["PlantSchedule"],
      doc: ["report"],
      pdf: ["PlantSchedule"],
    };

    const mimeTypeMap: { [key: string]: string[] } = {
      "image/": ["plantImage"],
      "text/": ["PlantSchedule"],
      "application/pdf": ["PlantSchedule"],
    };
    // TODO handle bad extensions
    const extension = fileMetadata.filename?.split(".").pop()?.toLowerCase();
    const toolsByExtension = extension && extensionMap[extension];
    
    const toolsByMimeType = Object.entries(mimeTypeMap)
      .filter(([key]) =>
        key.includes("/")
          ? fileMetadata.content_type === key
          : fileMetadata.content_type.startsWith(key)
      )
      .reduce((acc, [, value]) => acc.concat(value), []);

    const docTypes = toolsByExtension || (toolsByMimeType.length ? toolsByMimeType : ['defaultTool']);
    console.log('tools.ts: docTypes', docTypes);
    
    // use the docTypeToolMap to get the tool_uids and matchedTools
    const matchedTools = [...new Set(docTypes.flatMap((tool_id) => {
      const docTypes = docTypeToolMap[tool_id];
      return docTypes.map((docType) => tools.find((t) => t.id === docType));
    }))];
    
    const tool_uids = matchedTools.map(tool => tool?.uid);
    const tool_ids = matchedTools.map(tool => tool?.id);
    
    console.log('tools.ts: tool_uids', tool_uids);
    
    return { ...fileMetadata, tool_uids, tool_ids, tools, tool_objs: matchedTools };
  }

  const execTool = (opts: any, headers: any) => {
    console.log('executing', opts)
    // get tools from tool_uids
    const selectedTools = opts.tool_uids.map((tool_uid: any) => {
      return tools.find((tool: any) => tool.uid === tool_uid);
    });
    const promises = selectedTools.map((tool: any) => {      
      console.log('have uid?', tool.uid)
      if (tool?.apiRoute) {
        return $fetch(tool.apiRoute, {
          method: "POST",
          body: {
            ...opts.fileMetadata,
            inference: tool.uid,
            panel_id: opts.panel_id,
            tool_uid: tool.uid,
            ...opts,
          },
          headers,
        });
      }
      // else {
      //   return tool.action();
      // }
    }).filter(Boolean); // Filter out undefined values

    return promises;
  };

  return { tools, inferTool, docTypeToolMap, execTool };
}
<script setup lang="ts">
const { search } = useMeiliSearch('gbif')
import { useGeolocation } from '@vueuse/core'
import { Loader2 } from 'lucide-vue-next'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion'
import { useAsyncData } from '#app'

const { coords } = useGeolocation()

const dockStore = useDockStore()
const { activePanel, panelState, availablePanels } = storeToRefs(dockStore)
const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)

const { validateSpeciesNames, nameStatus: nameStatusResults, validationLoadingState, getSpeciesNames, fetchTnrs } = useValidation()
const { fetchSpeciesDataIndividually, results, fetchStatus: status } = useSearch()

const {data, metadata, cell, panel_id, project_id} = defineProps<{
  data?: string[],
  metadata?: any,
  cell?: any,
  project_id?: string,
  panel_id?: string
}>()

const emit = defineEmits(['results'])

const showHide = ref({
  exact: true,
  inexact: true,
  invalid: true
})

const speciesNames = ref([])

const tnrsData = ref([])
const tnrsError = ref(null)


const refresh = async () => {
  const names = await fetchSpeciesDataIndividually([...new Set(speciesNames.value)])
  if (names.length === 0) {
    return
  }
  const nameStatusResults = validateSpeciesNames(
    names.map(n => String(n?.[0]?.species)),
    speciesNames.value.map(n => String(n)),
    selectedProject.value?.id
  )
  emit('results', nameStatusResults)
  console.log(nameStatusResults.exact)
  showHide.value.exact = nameStatusResults.exact.length > 0
  showHide.value.inexact = nameStatusResults.inexact.length > 0
  showHide.value.invalid = nameStatusResults.invalid.length > 0

  // Fetch TNRS data for invalid names
  if (nameStatusResults.invalid.length) {
    console.log('fetching TNRS data for invalid names', toRaw(nameStatusResults.invalid.map(item => item?.original)))
    const invalidNames = toRaw(nameStatusResults.invalid).map(item => item?.original)
    const { data: tnrsResult, error: tnrsError } = await useAsyncData(
      `tnrs-${invalidNames.join(',')}`,
      () => fetchTnrs(invalidNames)
    )
    tnrsData.value = tnrsResult.value
    if (tnrsError.value) {
      console.error('Error fetching TNRS data:', tnrsError.value)
    }
  }
}

const getTnrsResult = (name) => {
  return tnrsData.value?.find(d => d.Name_submitted === name)
  
  
} 

onMounted(async () => {
  speciesNames.value = getSpeciesNames(data, panelState.value, panel_id, availablePanels.value)
  await refresh()
})

watchEffect(async () => {
  if (speciesNames.value.length > 0) {
    // await refresh()
  }
})

const colorMode = useColorMode()

</script>

<template>
  <div>  
    
    <div v-if="results?.length > 0" class="flex flex-col items-start mb-2">
      <div class="flex flex-cols items-center gap-1 ml-0">
        
        <Loader2 class="w-4 h-4 animate-spin" v-if="status === 'pending'" />
        <div class="mr-1">
        <Icon class="w-5 h-5" name="lets-icons:done-all-round-light"
          v-if="status === 'idle' && nameStatusResults?.exact.length === speciesNames.length" />
        <Icon class="w-5 h-5" name="lets-icons:done-all-round-duotone"
          v-if="status === 'idle' && nameStatusResults?.exact.length !== speciesNames.length" />
        </div>
        <div>
        <p v-if="status === 'pending'" class="text-sm text-muted-foreground">
          Validating species names against GBIF
        </p>
        <p v-if="status === 'idle'" class="text-xs text-muted-foreground">
          Species name check: <span class="font-semibold">{{ nameStatusResults?.exact.length }} exact {{
            nameStatusResults?.inexact.length ? `${nameStatusResults?.inexact.length} partial ` :  '' }} {{ nameStatusResults?.invalid.length ? `and ${nameStatusResults?.invalid.length}` : ''
            }} to review.</span>
            
        </p>
        
      </div>
      
      </div>
      <div class="relative mt-2 left-0">
            <p class="text-xs text-muted-foreground">Source: 
        <img v-if="colorMode.value == 'dark'" src="~/public/gbif-dot-org-white-logo.svg"
          class="w-18 h-6 inline-block brightness-[0.8]" />
        <img v-else src="~/public/gbif-dot-org-green-logo.svg" class="w-18 h-6 inline-block" />
      </p>
      </div>



      <div class="flex flex-col gap-2 w-full mt-5">
        <Accordion type="multiple" :default-value="['partial-match', 'invalid']" class="space-y-1">
          <AccordionItem v-for="(names, type) in { 
            'exact-match': nameStatusResults?.exact, 
            'partial-match': nameStatusResults?.inexact, 
            'to review': nameStatusResults?.invalid 
          }" :key="type" :value="type" class="rounded-sm accordion-item">
            <AccordionTrigger v-if="names.length" class="px-3 py-2 text-sm hover:no-underline !bg-background/80 rounded-md">
              <h3 class="font-semibold flex flex-row items-center gap-1">
                {{ type.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase()) }}
                <Badge
                  class="select-none bg-muted-foreground/20 hover:text-background text-muted-foreground text-xs py-0 px-1 font-normal whitespace-nowrap">
                  {{ names.length }}</Badge>
              </h3>
            </AccordionTrigger>
            <AccordionContent class="px-3 mt-2 py-1 border-b-0" v-if="names.length">
              <div v-if="type === 'exact-match'" class="flex flex-row flex-wrap gap-1">
                <Badge v-for="item in names" :key="item.original"
                  variant="default"
                  :class="[
                    'select-none text-xs py-0 px-2 font-normal whitespace-nowrap dark:bg-muted-foreground/10 dark:text-muted-foreground',
                    { 'font-bold border-0 border-none shadow-sm': item.matched === cell }
                  ]">
                  {{ item.original }}
                </Badge>
              </div>
              <div v-else class="flex flex-col gap-1">
                <div v-for="item in names" :key="item.original" 
                     :class="[
                       'flex items-center gap-2']">
                  <Badge 
                    :variant="type === 'partial-match' ? 'secondary' : 'destructive'"
                    class="select-none text-xs py-0 px-2 font-normal whitespace-nowrap" :class="{ 'bg-primary text-background': item.matched === cell }">
                    {{ item.original }}
                  </Badge>
                  <span class="text-xs border-0 border-transparent text-muted-foreground whitespace-nowrap truncate" :class="{ 'bg-background border-0 border-destructive': item.matched === cell }">
                    ({{ item.matched || 'Not found' }})
                  </span>
                </div>
              </div>
              <div v-if="tnrsData.length > 0 && type === 'to review'" class="flex flex-col flex-wrap gap-1 align-center items-start mt-5">
              <div class="text-accent-200 font-semibold tracking-tight">Did you mean:</div>
              <!-- {{tnrsData.find(d => d.Name_submitted === item.original)?.Name_matched}} -->
              <VTooltip v-for="item in [...new Set(nameStatusResults.invalid)]" :key="item.original" :animate="true" side="bottom"
                :content="{__html: `<div class='max-w-[400px]'>Taxonomic Name Resolution Service, Boyle, B. L., Matasci, N., Mozzherin, D., Rees, T., Barbosa, G. C., Kumar Sajja, R., & Enquist, B. J. (2021). Taxonomic Name Resolution Service, version 5.1. In Botanical Information and Ecology Network. https://tnrs.biendata.org/</div>`}">
                <div class="flex flex-row gap-2 mt-2 pl-1 text-[0.9em] -ml-1">
                  <img src="/bien.png" class="w-10 h-auto -mt-[2px] self-center" />
                  <a v-if="tnrsData" target="_blank" class="underline" :href="getTnrsResult(item.original)?.Name_matched_url">
                    {{ getTnrsResult(item.original)?.Name_matched }}  
                    <Icon name="radix-icons:open-in-new-window" class="h-4 w-4 ml-1 -mt-[2px]" />
                  </a>
                </div>
              </VTooltip>
            </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      

    </div>
    
  </div>
</template>
<style scoped>
.accordion-content {
  padding: 0.25rem 0.75rem;
}
:deep(.accordion-item) {
  border-bottom: none;
}

:deep(.accordion-item > div) {
  border-bottom: none;
}

:deep(.accordion-item > div > button) {
  border-bottom: none;
}

</style>


<script setup lang="ts">
import PanelComponent from './PanelComponent.vue'

const uiStore = useUiStore()
const {windowHeight} = storeToRefs(uiStore)
const { $useLicense } = useNuxtApp()

const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true }
});


const { data, status } = await useFetch('/api/vpv-license-key')

console.log('do wa have', data.value.licenseKey)


$useLicense({ licenseKey: data.value.licenseKey })

const emit = defineEmits(['error', 'update:shared-data']);

// Example of using shared data
// const sharedData = computed(() => props.panel.data.sharedData['some-panel-id'] || {})

const isLoading = ref(true)

const updatePanelData = () => {
  emit('update:shared-data', {
    
  })
}

const onLoaded = () => {
  console.log('loaded')
}

const colorMode = useColorMode()

const toolbarOptions = {
  fullscreen: false,
  themeSwitchable: false
}

</script>

<template>
  <PanelComponent :panel="props.panel" :height="props.height" :panel_id="props.panel_id" toolLabel="Pdf" dataKey="url">
    <template #default="{ data, tool_id, updateState }">
      <keep-alive>
        <div class="overflow-hidden w-full h-full p-0 m-0 rounded-md">
          <div class="flex justify-center align-center items-center w-full h-full">
            <ClientOnly>
              <MyPDFViewer
              :licenseKey="data?.licenseKey"
              :toolbarOptions="toolbarOptions" @loaded="onLoaded" 
                class="w-full h-full" src="/reveg.pdf" />
            </ClientOnly>
          </div>
        </div>
      </keep-alive>
    </template>
  </PanelComponent>
</template>

<style>
@import 'vue-pdf-embed/dist/styles/annotationLayer.css';
@import 'vue-pdf-embed/dist/styles/textLayer.css';

.vue-pdf-embed {
  width: 100%;
  height: 100%;
}
</style>

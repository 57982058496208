<script setup lang="ts">
import PanelComponent from './PanelComponent.vue'
import { useValidationStore } from '@/stores/validationStore'
import { useProjectStore } from '@/stores/projectStore'


const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true },
});

const validationStore = useValidationStore()
const projectStore = useProjectStore()

const { selectedProject } = storeToRefs(projectStore)
const { allValidations } = storeToRefs(validationStore)

const biosecurityValidation = computed(() => {
  return allValidations.value[selectedProject.value.id]?.biosecurityStatus
})
</script>

<template>
  <PanelComponent :panel="props.panel" :height="props.height" :panel_id="props.panel_id"
     toolLabel="Review" dataKey="review">
    <template #default="{ data, tool_id, updateState }">
      <keep-alive>
        <div class="overflow-hidden w-full h-full p-0 m-0 rounded-md p-2 pl-4 pb-10">
          <div class="flex flex-col justify-start align-start items-start w-full h-full">
            <h2 class="text-xl font-bold mb-4">Biosecurity Validation Results</h2>
            <div v-if="biosecurityValidation">
              <div v-for="(result, species) in biosecurityValidation.result" :key="species">
                <h3 class="text-lg font-semibold mt-2">{{ species }}</h3>
                <p v-if="result.isBiosecurityThreat" class="text-red-500">
                  This species is a potential biosecurity threat.
                </p>
                <p v-else class="text-green-500">
                  No biosecurity threats detected for this species.
                </p>
                <div v-if="result.hosts.length > 0">
                  <h4 class="text-md font-medium mt-1">Hosts:</h4>
                  <ul>
                    <li v-for="host in result.hosts" :key="host.name">
                      {{ host.name }} ({{ host.type }})
                    </li>
                  </ul>
                </div>
                <div v-if="result.pests.length > 0">
                  <h4 class="text-md font-medium mt-1">Pests:</h4>
                  <ul>
                    <li v-for="pest in result.pests" :key="pest.name">
                      {{ pest.name }} ({{ pest.type }})
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div v-else>
              <p>No biosecurity validation results available.</p>
            </div>
          </div>
        </div>
      </keep-alive>
    </template>
  </PanelComponent>
</template>
<script setup lang="ts">

const props = defineProps<{
  col: any
  row: any
  cell: any
  metadata: any
  title: string
  botanicalnames: any
  species: any
}>()
// console.log(props.col)
const uniqueInCol = computed(() => {
  return [...new Set(props.col)]
})

console.log('rowstats', props.row)

</script>

<template>
  <div
    class="border-b-2 border-l-2 border-r-2 border-t-2 border-primary/60 rounded-bl-md rounded-br-md w-full min-h-fit flex flex-col p-3 space-y-3 text-muted-foreground bg-background">
    <div class="h-fit">
       <h3 class="scroll-m-20 text-2xl font-semibold tracking-tight">
        {{ props.species }}
        </h3>

        
        
        
      <!-- <UplotVue v-if="props.col" mode="points" :data="props.col" /> -->
    </div>

    <!-- {{ props.col }} -->
    <!-- {{ props.row }} -->
    <!-- {{ props.cell }} -->

  </div>
</template>

<style scoped>

</style>


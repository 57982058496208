<template>
  <div ref="containerRef" :class="`scale-[${scale}] origin-top-left`" class="w-full inline-block">
    <div class="flex items-center w-full">
      <div v-if="!isEditing" 
           @click="startEditing" 
           class="cursor-pointer w-full flex items-center"
           :class="[previewClass]">
        <span class="truncate">{{ modelValue }}</span>
      </div>
      <div v-else class="flex items-center gap-2" :class="{ 'w-full': !allowOverflow }">
        <Input v-model="editValue" 
               ref="inputRef"
               :class="['px-2 flex-grow truncate', inputClass]"
               :style="getInputStyle()"
               @keyup.enter="saveEdit" />
        <div class="flex items-center gap-1 shrink-0">
          <Button variant="ghost" @click="saveEdit" class="size-6" size="icon-sm">
            <Icon :name="saveIcon" class="size-4" />
          </Button>
          <Button variant="ghost" @click="cancelEdit" class="size-6" size="icon-sm">
            <Icon :name="cancelIcon" class="size-4" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMagicKeys, onClickOutside } from '@vueuse/core';

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  previewClass: {
    type: String,
    default: 'text-muted-foreground'
  },
  inputClass: {
    type: String,
    default: ''
  },
  buttonsWidth: {
    type: Number,
    default: 80
  },
  saveIcon: {
    type: String,
    default: 'lucide:check'
  },
  cancelIcon: {
    type: String,
    default: 'lucide:x'
  },
  scale: {
    type: Number,
    default: 1
  },
  allowOverflow: {
    type: Boolean,
    default: true
  },
  minInputWidth: {
    type: Number,
    default: 90
  },
  maxInputWidth: {
    type: Number,
    default: Infinity
  }
});

const emit = defineEmits(['update:modelValue', 'edit']);
const isEditing = ref(false);
const editValue = ref(props.modelValue);
const containerRef = ref(null);
const inputRef = ref(null);

const { escape } = useMagicKeys();

watch(escape, (pressed) => {
  if (pressed && isEditing.value) {
    cancelEdit();
  }
});

onClickOutside(containerRef, () => {
  if (isEditing.value) {
    cancelEdit();
  }
});

onMounted(() => {
  startEditing()
});

watch(() => props.modelValue, (newValue) => {
  editValue.value = newValue;
});

const startEditing = () => {
  editValue.value = props.modelValue;
  isEditing.value = true;
  emit('edit', true); // Emit the 'edit' event when entering edit mode
  nextTick(() => {
    if (inputRef.value) {
      inputRef.value.focus();
      inputRef.value.setSelectionRange(editValue.value.length, editValue.value.length);
    }
  });
};

const saveEdit = () => {
  emit('update:modelValue', editValue.value);
  isEditing.value = false;
  emit('edit', false); // Emit the 'edit' event when exiting edit mode
};

const cancelEdit = () => {
  isEditing.value = false;
  emit('edit', false); // Emit the 'edit' event when canceling edit mode
};

const getInputStyle = () => {
  const baseStyle = {
    minWidth: `${props.minInputWidth}px`,
    maxWidth: props.maxInputWidth === Infinity ? 'none' : `${props.maxInputWidth}px`,
    width: 'auto',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
    fontFamily: 'inherit'
  };

  if (!props.allowOverflow) {
    baseStyle.width = `calc(100% - ${props.buttonsWidth}px)`;
  }

  return baseStyle;
};
</script>

<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Remove the .text-style class and its rules */
</style>
    <script setup>
import { useMagicKeys, whenever } from '@vueuse/core'

const props = defineProps({
  enableHovers: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['update:enableHovers'])

const states = [
    { icon: 'mingcute:columns-2-fill', label: 'Column hover', value: { row: false, column: true } },
    { icon: 'mingcute:rows-2-fill', label: 'Row hover', value: { row: true, column: false } },
    { icon: 'fluent:text-column-one-wide-24-filled', label: 'No hover', value: { row: false, column: false } }
]

const currentStateIndex = ref(0)

const cycleState = () => {
  currentStateIndex.value = (currentStateIndex.value + 1) % states.length
  emit('update:enableHovers', states[currentStateIndex.value].value)
}

const getCurrentState = () => states[currentStateIndex.value]

// Use useMagicKeys to detect spacebar press
const { space } = useMagicKeys()

// Cycle state when spacebar is pressed
whenever(space, cycleState)
</script>

<template>
    <TooltipProvider :delayDuration="200" :skipDelayDuration="700">
        <Tooltip>
            <TooltipTrigger>
                <Button @click="cycleState" variant="outline" class="w-10 h-10 p-0 saturate-50">
                    <Icon :name="getCurrentState().icon" class="w-6 h-6" />
                    <span class="sr-only">{{ getCurrentState().label }}</span>
                </Button>
            </TooltipTrigger>
            <TooltipContent 
                class="pointer-events-all bg-primary text-background shadow-xxl !animate-none select-none border-none">
                <p>Toggle Column/Row panel (Spacebar)</p>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
</template>
<script setup lang="ts">
import type { VChart } from '#components'
import { use } from 'echarts/core'
import { ToolboxComponent } from 'echarts/components'
import { ScatterChart } from 'echarts/charts'
import { watch, ref, computed } from 'vue'

use([ToolboxComponent, ScatterChart])

const props = defineProps<{
    data: any[] | null | undefined
    cm: string
    metadata: any
    title: string
    sizes_codes: any
    columnTitle: string
    cell: string | number
    heightValues: any[]
    widthValues: any[]
}>()

const chart = ref<InstanceType<typeof VChart> | null>(null)
const chartContainer = ref(null)
const initOptions = ref({
    renderer: 'svg'
})

// console.log('countfield', props.sizes_codes)

const colors = computed(() => {
    if (props.cm === 'dark') {
        return {
            mutedForeground: 'hsl(90 20.2% 65.1%)',
            accent: 'hsl(160 32.6% 21.5%)',
            muted: 'hsl(160 22.6% 13.5%)',
            primary: 'hsl(90 20% 84%)'
        }
    } else {
        return {
            mutedForeground: 'hsl(160 21% 36.9%)',
            accent: 'hsl(160 30% 79.1%)',
            muted: 'hsl(90 11% 91%)',
            primary: 'hsl(120 27.4% 21.2%)'
        }
    }
})

const option = ref(null)

/**
 * Converts a value to a numeric float, replacing non-numeric values with 1.0.
 */
const toNumber = (value: any): number => {
    if (typeof value === 'string') {
        // Remove any non-numeric characters except for decimal point
        const cleanedValue = value.replace(/[^\d.-]/g, '');
        const num = parseFloat(cleanedValue);
        return isNaN(num) ? 0 : num;
    }
    const num = Number(value);
    return isNaN(num) ? 0 : num;
}

/**
 * Parses a size string into height and width components.
 */
const parseSize = (size: string): [number, number] => {
    // console.log('Parsing size:', size);
    if (typeof size !== 'string') {
        console.warn('Size is not a string:', size);
        return [1, 1]; // Default values
    }
    const parts = size.split('x').map(part => toNumber(part.trim()));
    const result = parts.length === 2 ? parts as [number, number] : [parts[0], parts[0]];
    // console.log('Parsed size result:', result);
    return result;
}

/**
 * Creates a histogram based on the available data.
 */
const createHistogram = () => {
  const histogram = new Map<string, number>();
  
//   console.log('Creating histogram with:');
//   console.log('heightValues:', props.heightValues);
//   console.log('widthValues:', props.widthValues);
//   console.log('sizes_codes:', props.sizes_codes);
//   console.log('data:', props.data);

  if (props.heightValues.length && props.widthValues.length) {
    props.heightValues.forEach((height, index) => {
      const width = props.widthValues[index];
      const key = `${toNumber(height)}x${toNumber(width)}`;
      histogram.set(key, (histogram.get(key) || 0) + 1);
    });
  } else if (props.sizes_codes && Object.keys(props.sizes_codes).length > 0) {
    Object.entries(props.sizes_codes).forEach(([key, size]) => {
    //   console.log(`Processing size for key ${key}:`, size);
      const [height, width] = parseSize(size);
      const histKey = `${height}x${width}`;
      histogram.set(histKey, (histogram.get(histKey) || 0) + 1);
    });
  } else if (props.data) {
    props.data.forEach(size => {
      const [height, width] = parseSize(size);
      const key = `${height}x${width}`;
      histogram.set(key, (histogram.get(key) || 0) + 1);
    });
  }

//   console.log('Created histogram:', Object.fromEntries(histogram));
  return histogram;
}

/**
 * Watches for changes in data, colors, and metadata to update the chart options.
 */
watch([() => props.sizes_codes, () => props.heightValues, () => props.widthValues, () => props.data, colors, () => props.metadata, () => props.cell], ([newSizesCodes, newHeightValues, newWidthValues, newData, newColors, newMetadata, newCell]) => {
    // console.log('Watch triggered with:');
    // console.log('newSizesCodes:', newSizesCodes);
    // console.log('newHeightValues:', newHeightValues);
    // console.log('newWidthValues:', newWidthValues);
    // console.log('newData:', newData);
    // console.log('newCell:', newCell);

    if ((!newSizesCodes || Object.keys(newSizesCodes).length === 0) && 
        (!newHeightValues || !newWidthValues) && 
        (!newData || newData.length === 0)) {
        // console.log('No valid data, setting option to null');
        option.value = null
        return
    }

    const histogram = createHistogram()

    let bubbleData;
    if (props.heightValues.length && props.widthValues.length) {
        bubbleData = props.heightValues.map((height, index) => {
            const width = props.widthValues[index];
            const numericHeight = toNumber(height);
            const numericWidth = toNumber(width);
            const count = histogram.get(`${numericHeight}x${numericWidth}`) || 1;
            return [numericWidth, numericHeight, count, `Plant ${index + 1}`, `${height}x${width}`];
        }).filter(item => item[0] && item[1]);
    } else if (props.sizes_codes && Object.keys(props.sizes_codes).length > 0) {
        bubbleData = Object.entries(props.sizes_codes).map(([botanicalName, size]) => {
            // console.log(`Processing size for ${botanicalName}:`, size);
            const [height, width] = parseSize(size);
            const count = histogram.get(`${height}x${width}`) || 1;
            return [width, height, count, botanicalName, `${height}x${width}`];
        }).filter(item => item[0] && item[1]);
    } else if (props.data) {
        bubbleData = props.data.map((size, index) => {
            const [height, width] = parseSize(size);
            const count = histogram.get(`${height}x${width}`) || 1;
            return [width, height, count, `Plant ${index + 1}`, size];
        }).filter(item => item[0] && item[1]);
    }

    // console.log('Created bubbleData:', bubbleData);

    // Find the maximum count for scaling
    const maxCount = Math.max(...bubbleData.map(item => item[2]))

    option.value = {
        animationDuration: 0,
        tooltip: {
            trigger: 'item',
            formatter: (params: any) => {
                const [width, height, count, botanicalName, size] = params.data
                return `${botanicalName}<br/>Size: ${size}<br/>Count: ${count}`
            }
        },
        grid: {
            left: '10%',
            right: '5%',
            bottom: '15%',
            top: '10%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            name: 'Width (m)',
            nameLocation: 'middle',
            nameGap: 30,
            axisLabel: {
                color: newColors.mutedForeground,
                formatter: (value: number) => value.toFixed(2)
            },
            axisLine: {
                lineStyle: {
                    color: newColors.muted
                }
            },
            splitLine: {
                lineStyle: {
                    color: newColors.muted
                }
            }
        },
        yAxis: {
            type: 'value',
            name: 'Height (m)',
            nameLocation: 'middle',
            nameGap: 40,
            axisLabel: {
                color: newColors.mutedForeground,
                formatter: (value: number) => value.toFixed(2)
            },
            axisLine: {
                lineStyle: {
                    color: newColors.muted
                }
            },
            splitLine: {
                lineStyle: {
                    color: newColors.muted
                }
            }
        },
        series: [
            {
                name: 'Distribution',
                type: 'scatter',
                symbolSize: (data: any[]) => {
                    const count = data[2]
                    return Math.max(10, (count / maxCount) * 50)
                },
                data: bubbleData,
                itemStyle: {
                  color: (params: any) => {
                        // Change color based on the current cell
                        const [width, height] = params.data;
                        const cellValue = toNumber(newCell);
                        return (width === cellValue || height === cellValue)
                            ? newColors.accent
                            : newColors.mutedForeground
                    }
                },
                emphasis: {
                    itemStyle: {
                        color: newColors.accent
                    }
                }
            }
        ],
        textStyle: {
            color: newColors.primary
        }
    }
}, { immediate: true })

const hasValidData = ref(false)

watch(() => option.value, (newOption) => {
    hasValidData.value = newOption !== null
})

// Optional: Functions for color manipulation can remain unchanged
function hslToRgb(hsl) {
    const [h, s, l] = hsl.split(' ').map(Number)
    const a = s * Math.min(l, 1 - l)
    const f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return `rgb(${Math.round(f(0) * 255)}, ${Math.round(f(8) * 255)}, ${Math.round(f(4) * 255)})`
}

function getRGBColor(variable) {
    const style = getComputedStyle(document.documentElement)
    const color = style.getPropertyValue(variable).trim()
    return hslToRgb(color)
}
</script>

<template>
  <div>
    <div ref="chartContainer" class="chart-container p-0 m-0 flex flex-col items-start">
      <p class="self-start text-xs pl-12 -mb-3 w-[90%] ">Bubble chart plants of varying <Badge
          class="bg-muted-foreground/10 text-muted-foreground text-xs py-0 px-1 font-normal">{{
          props.columnTitle }}</Badge> and their quantities (circle diameter)
      </p>

      <VChart v-if="hasValidData" ref="chart" :option="option" :init-options="initOptions" :autoresize="true" />
      <div v-else class="no-data-message">No valid data available for visualization</div>
    </div>
  </div>
</template>

<style scoped>
.chart-container {
    width: 100%;
    height: 400px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-data-message {
    font-size: 16px;
    color: var(--colors-primary); /* Updated to use CSS variable correctly */
}
</style>
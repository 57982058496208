import { defineStore } from 'pinia'
import { Validation, PanelValidations } from "@/types/validation";

export const useValidationStore = defineStore(
  "ValidationStore",
  () => {
    const allValidations = ref<Record<string, PanelValidations>>({});

    const addValidation = (validation: Validation, project_id: string) => {
      if (!allValidations.value[project_id]) {
        allValidations.value[project_id] = {};
      }
      allValidations.value[project_id][validation.name] = validation;
    };

    const removeAllValidations = (project_id: string) => {
      allValidations.value[project_id] = {};
    };

    return {
      allValidations,
      addValidation,
      removeAllValidations
    };
  },
  {
    persist: true,
  }
);